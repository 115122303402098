/* You can add global styles to this file, and also import other style files */
@import "app/app.component.scss";

html,
body {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #eceefe;
}

.custom-container .mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 30px 0px 30px 30px;
}

//all fonts
/* nunito-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: normal;
  font-weight: 200;
  src: url("assets/fonts/nunito-v26-latin-200.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: italic;
  font-weight: 200;
  src: url("assets/fonts/nunito-v26-latin-200italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  src: url("assets/fonts/nunito-v26-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: italic;
  font-weight: 300;
  src: url("assets/fonts/nunito-v26-latin-300italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/nunito-v26-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  src: url("assets/fonts/nunito-v26-latin-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  src: url("assets/fonts/nunito-v26-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: italic;
  font-weight: 500;
  src: url("assets/fonts/nunito-v26-latin-500italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: url("assets/fonts/nunito-v26-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  src: url("assets/fonts/nunito-v26-latin-600italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: url("assets/fonts/nunito-v26-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: italic;
  font-weight: 700;
  src: url("assets/fonts/nunito-v26-latin-700italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  src: url("assets/fonts/nunito-v26-latin-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: italic;
  font-weight: 800;
  src: url("assets/fonts/nunito-v26-latin-800italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  src: url("assets/fonts/nunito-v26-latin-900.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito";
  font-style: italic;
  font-weight: 900;
  src: url("assets/fonts/nunito-v26-latin-900italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

// Main colors
$purple1: #444df2;
$purple2: #797ef3;
$purple3: #535af1;
$light-purple: #adb0d9;
$bg-color: #eceefe;

// app-login styling
app-login {
  height: 100vh;
  @include dFlex($jc: unset, $ai: center);
  flex-direction: column;
}
app-create-account {
  height: 100vh;
  @include dFlex($jc: unset, $ai: center);
  flex-direction: column;
}
app-create-avatar {
  height: 100vh;
  @include dFlex($jc: unset, $ai: center);
  flex-direction: column;
}


// purple Link styling
.purple-link {
  color: $purple2;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  border-radius: 30px;
  padding: 5px 15px 5px 15px;
  &:hover {
    background-color: $bg-color;
  }
}

// use with purple-link
.link-border-effect {
  border: 1px solid $bg-color;
  &:hover {
    color: $purple1;
    border: 1px solid $light-purple;
  }
}

//use with purple link
.link-bg-effect {
  &:hover {
    color: $purple1;
  }
}

// size of the regular button used on Loginpage or edit user
.main-btn {
  min-width: 137px;
  height: 49px;
  border-radius: 25px;
  font-family: Nunito;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  transition: ease-in 125ms;
}

// use with .main-btn class to get the dark pruple button
.purple1-btn {
  border: 0;
  font-size: 18px;
  color: white;
  background-color: $purple1;
  &:hover {
    background-color: $purple2;
  }
}

// use with .main-btn class to get the white  button with purple hover
.white-btn {
  border: 1px solid $purple2;
  background-color: white;
  color: $purple1;
  &:hover {
    color: white;
    background-color: $purple1;
  }
}

// use with .main-btn class
.disabled-btn {
  background-color: #686868;
  color: white;
  cursor: not-allowed;
  border: 0;
}
.arrow-back-btn {
  position: absolute;
  top: 38px;
  left: 30px;
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 50px;
  background-color: white;
  @include dFlex($jc: center, $ai: center);
  transition: ease-in-out 125ms;
  cursor: pointer;
  z-index: 2;

  &:hover {
    background-color: $bg-color;

    .mat-icon {
      color: $purple3;
    }
  }
}

.d-none {
  display: none !important;
}

.nunito {
  font-family: Nunito, Roboto, Helvetica, sans-serif;
}

.nunito20px{
  font-family: Nunito, Roboto, Helvetica, sans-serif;
  font-size: 20px;
}
